import i18n from './i18n';

let checkbox = (v) => {
  if (v) {
    return true;
  }
  return 'This field is required';
};

let israeliId = (id) => {
  if (id.length !== 9 || isNaN(id)) {
    // Make sure ID is formatted properly
    return i18n.messages[i18n.locale].validation_errors.israliId_length;
  }
  let sum = 0,
    incNum;
  for (const i in id) {
    incNum = Number(id[i]) * ((i % 2) + 1); // Multiply number by 1 or 2
    sum += incNum > 9 ? incNum - 9 : incNum; // Sum the digits up and add to total
  }
  if (sum % 10 === 0) {
    return true;
  }
  return i18n.messages[i18n.locale].validation_errors.israliId_validation;
};

export { checkbox, israeliId };
